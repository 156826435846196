.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.shadow-point {
  position: absolute;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  animation: shadow 1s infinite alternate;
}

@keyframes shadow {
  from {
    box-shadow: 0 0 225px 50px black;
  }
  to {
    box-shadow: 0 0 450px 100px black;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-enter {
  opacity: 0;
  transform: scale(0.9);
}
.page-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.page-exit {
  opacity: 1;
}
.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

img{
  width: 100%;
  border-radius: 10px;
  animation-name: Appearance;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(.1,-.6,.2,0);
}
@-webkit-keyframes Appearance {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@-o-keyframes Appearance {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@-moz-keyframes Appearance {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes Appearance {
  0% {opacity: 0;}
  100% {opacity: 1;}
}