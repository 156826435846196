@font-face {
  font-family: "CocoGothincRegular";
  src: url("../fonts/Coco-Gothic/Coco-Gothic-Regular-trial.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

body {
  margin: 0;
  font-family: 'Comfortaa', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}